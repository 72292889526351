<template>
  <div>
    <Footer></Footer>
      <div class="content">
        <span class="title">
          “大唐杯”全国大学生新一代信息通信技术大赛-往届回顾
          <p class="titext">
            <!--<span>学唐小助手</span>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            <span>发布于2022-07-19</span>-->
          </p>
        </span>

        <!--<div class="imgbox">
          <img  style="width:100%;height: 100%;" src="./Retrospect_img/img_A.jpg" alt=""> 
        </div>-->

        <div class="contbox">
          <p class="text">1、往届大赛规模</p>

          <p class="text_t">“大唐杯”全国大学生新一代信息通信技术大赛由工业和信息化部人才交流中心、中国通信企业协会主办，中信科移动通信技术股份有限公司、大唐移动通信设备有限公司承办，北方工业大学、北京邮电大学、电子科技大学、北京航空航天大学、北京化工大学、武汉理工大学、武汉职业技术学院、武汉软件工程职业学院协办。“大唐杯”连续四年入选全国普通高校大学生竞赛分析报告竞赛目录，始终践行“以赛促学、以赛促教”的办赛理念，围绕教育部、工信部倡导的“政、产、学、研、用”推动产教深度融合，落实落地新工科人才培养新举措，创新工程人才培养体系，提升创新拔尖人才培养质量。</p>
          <p class="text_t">大赛自2013年开办以来，从数百人规模逐年发展至2024年的超五万人规模，单届最大参赛学校规模超650余所，累计参赛学校规模近千所。</p>
            <div class="formWeight">
              <div class="Item-head">
                <div class="item-wrap">历届</div>
                <div class="item-wrap">参赛学生规模</div>
                <div class="item-wrap">省赛学校规模</div>
                <div class="item-wrap">参赛省份</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第一届</div>
                <div class="box-one">800+</div>
                <div class="box-one">61+</div>
                <div class="box-one">20</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第二届</div>
                <div class="box-one">1600+</div>
                <div class="box-one">120+</div>
                <div class="box-one">21</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第三届</div>
                <div class="box-one">3000+</div>
                <div class="box-one">150+</div>
                <div class="box-one">26</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第四届</div>
                <div class="box-one">3800+</div>
                <div class="box-one">230+</div>
                <div class="box-one">26</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第五届</div>
                <div class="box-one">4700+</div>
                <div class="box-one">300+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第六届</div>
                <div class="box-one">10000+</div>
                <div class="box-one">300+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第七届</div>
                <div class="box-one">12000+</div>
                <div class="box-one">320+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第八届</div>
                <div class="box-one">15000+</div>
                <div class="box-one">350+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第九届</div>
                <div class="box-one">25000+</div>
                <div class="box-one">390+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第十届</div>
                <div class="box-one">35000+</div>
                <div class="box-one">400+</div>
                <div class="box-one">31</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第十一届</div>
                <div class="box-one">50000+</div>
                <div class="box-one">650+</div>
                <div class="box-one">31</div>
              </div>
            </div>
          <!-- <p class="text">2、往届大赛相关新闻链接</p>
            <div class="formItem">
              <div class="Item-head">
                <div class="item-wrap">大赛</div>
                <div class="item-wrap">媒体</div>
                <div class="item-wrap">网址</div>
              </div>
              <div class="Item-body">
                <div class="box-one">第一届</div>
                <div class="box-one">
                  <div class="child-box">新网王</div>
                  <div class="child-box">大厦</div>
                  <div class="child-box">北工大</div>
                </div>
                <div class="box-one">
                  <div class="child-box">https://news.nxu.edu.cn/info/1006/3562.htm</div>
                  <div class="child-box" style="line-height:0.25rem">http://www.datangmobile.cn/NewsAndMagazine/NewsContentPage.aspx?CategoryID=1&NewsID=2cde1855-392f-4919-b5fc-ad9709156d9b</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/1105.htm</div>
                </div>
              </div>
              <div class="Item-body">
                <div class="box-one">第二届</div>
                <div class="box-one">
                  <div class="child-box">C114</div>
                  <div class="child-box">C114</div>
                  <div class="child-box">北工大</div>
                </div>
                <div class="box-one">
                  <div class="child-box" style="line-height:0.25rem">http://www.datangmobile.cn/NewsAndMagazine/NewsContentPage.aspx?CategoryID=1&NewsID=07c1add6-dc1c-4f11-998d-bc91374e93fa</div>
                  <div class="child-box" style="line-height:0.25rem">http://www.datangmobile.cn/NewsAndMagazine/NewsContentPage.aspx?NewsID=311953d0-9f82-4247-9a59-4310d45c36f1&&CategoryID=4</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/1157.htm</div>
                </div>
              </div>
              <div class="Item-body-t">
                <div class="box-one">第三届</div>
                <div class="box-one">
                  <div class="child-box">北方工大</div>
                  <div class="child-box">新闻网</div>
                </div>
                <div class="box-one">
                  <div class="child-box">http://front.ncut.edu.cn/xww/2/20160927/134910329232977.html</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/2956.htm</div>
                </div>
              </div>
              <div class="Item-body-s">
                <div class="box-one">第四届</div>
                <div class="box-one">
                  <div class="child-box">东方网</div>
                  <div class="child-box">新华网</div>
                  <div class="child-box">光明网讯</div>
                  <div class="child-box">北工大</div>
                </div>
                <div class="box-one">
                  <div class="child-box">http://news.eastday.com</div>
                  <div class="child-box">http://www.xinhuanet.com/expo/hzjy/2017-09/27/c_1121730339.htm</div>
                  <div class="child-box">http://wenyi.gmw.cn/2017-09/26/content_26345772.htm</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/7168.htm</div>
                </div>
              </div>
              <div class="Item-body">
                <div class="box-one">第五届</div>
                <div class="box-one">
                  <div class="child-box">北工大</div>
                  <div class="child-box">新闻网</div>
                  <div class="child-box"></div>
                </div>
                <div class="box-one">
                  <div class="child-box">http://www.ncut.edu.cn/info/1084/6185.htm</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/3847.htm</div>
                  <div class="child-box">http://www.zptc.cn/info/1025/2315.htm</div>
                </div>
              </div>
              <div class="Item-body-s">
                <div class="box-one">第六届</div>
                <div class="box-one">
                  <div class="child-box">人民网</div>
                  <div class="child-box">新闻网</div>
                  <div class="child-box"></div>
                  <div class="child-box"></div>
                </div>
                <div class="box-one">
                  <div class="child-box" style="line-height:0.25rem">http://yn.people.com.cn/GB/news/yunnan/n2/2019/0625/c381159-33076119.html</div>
                  <div class="child-box">http://news.ncut.edu.cn/info/1096/3847.htm</div>
                  <div class="child-box">http://www.sptpc.com/Item/5388.aspx</div>
                  <div class="child-box">http://www.yzpc.edu.cn/2019/1209/c386a29147/page.htm</div>
                </div>
              </div>
              <div class="Item-body-s">
                <div class="box-one">第七届</div>
                <div class="box-one">
                  <div class="child-box">中国通信企业协会</div>
                  <div class="child-box">我爱竞赛网</div>
                  <div class="child-box">学校网</div>
                  <div class="child-box">学校网</div>
                </div>
                <div class="box-one">
                  <div class="child-box">https://www.cace.org.cn/NEWS/COUNT?a=1930</div>
                  <div class="child-box">http://m.52jingsai.com/article-10633.html</div>
                  <div class="child-box">https://wt.jit.edu.cn/info/1112/2597.htm</div>
                  <div class="child-box">http://www.qdhhc.edu.cn/dashuju/p_detail.aspx?id=18110</div>
                </div>
              </div>
              <div class="Item-body-s">
                <div class="box-one">第八届</div>
                <div class="box-one">
                  <div class="child-box">大唐杯官网</div>
                  <div class="child-box">学校网</div>
                  <div class="child-box">学校网</div>
                  <div class="child-box">学校网</div>
                </div>
                <div class="box-one">
                  <div class="child-box">http://dtcup.dtxiaotangren.com/</div>
                  <div class="child-box">https://cs.wfu.edu.cn/2021/0624/c5459a165799/page.htm</div>
                  <div class="child-box">https://eie.hyit.edu.cn/info/1062/2165.htm</div>
                  <div class="child-box">http://www.hxut.edu.cn/plus/view.php?aid=146178</div>
                </div>
              </div>
              <div class="Item-body-s">
                <div class="box-one">第九届</div>
                <div class="box-one">
                  <div class="child-box">大唐杯官网</div>
                  <div class="child-box">我爱竞赛网</div>
                  <div class="child-box">搜狐网</div>
                  <div class="child-box">搜图网</div>
                </div>
                <div class="box-one">
                  <div class="child-box">http://dtcup.dtxiaotangren.com/</div>
                  <div class="child-box">http://m.52jingsai.com/article-16015.html</div>
                  <div class="child-box">https://www.sohu.com/a/516242441_121124791</div>
                  <div class="child-box">https://www.aisoutu.com/a/1374999</div>
                </div>
              </div>
            </div> -->
          <p class="text">2、大赛历届风采</p>
          <p class="text_f">（1）第一届</p>
            <div class="item_img">
              <img src="@/assets/images/sh1.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh2.jpg" />
            </div>
            <p class="text_f">（2）第二届</p>
            <div class="item_img">
              <img src="@/assets/images/sh3.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh4.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh5.jpg" />
            </div>
            <p class="text_f">（3）第三届</p>
            <div class="item_img">
              <img src="@/assets/images/sh6.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh7.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh8.jpg" />
            </div>

            <p class="text_f">（4）第四届</p>
            <div class="item_img">
              <img src="@/assets/images/sh9.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh10.jpg" />
            </div>
            <p class="text_f">（5）第五届</p>
            <div class="item_img">
              <img src="@/assets/images/sh11.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh12.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh13.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh14.jpg" />
            </div>

            <p class="text_f">（6）第六届</p>
            <div class="item_img">
              <img src="@/assets/images/sh15.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh16.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh17.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh18.jpg" />
            </div>

            <p class="text_f">（7）第七届</p>
            <div class="item_img">
              <img src="@/assets/images/sh19.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh20.png" />
            </div>

            <p class="text_f">（8）第八届</p>
            <div class="item_img">
              <img src="@/assets/images/sh21.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh22.png" />
            </div>

            <p class="text_f">（9）第九届</p>
            <div class="item_img">
              <img src="@/assets/images/sh23.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh24.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh25.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/sh26.jpg" />
            </div>
            <p class="text_f">（10）第十届</p>
            <div class="item_img">
              <img src="@/assets/images/yuwan/tenthOne.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/yuwan/tenthTwo.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/yuwan/tenthThree.jpg" />
            </div>
            <p class="text_f">（11）第十一届</p>
            <div class="item_img">
              <img src="@/assets/images/yuwan/eleventh_one.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/yuwan/eleventh_two.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/yuwan/eleventh_three.png" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/yuwan/eleventh_four.jpg" />
            </div>
            <div class="img_box">
            </div>
        </div>
      </div>
  <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
  export default {
    name: 'Home',
    components: {
        Footer,
        Bottom
    },
    data(){
      return {

      }
    },
    methods:{
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">

  @import './Retrospect.scss';
  </style>
  